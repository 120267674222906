@import url(https://fonts.googleapis.com/css2?family=Varela+Round&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Itim&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:ital@1&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital@1&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

